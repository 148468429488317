@font-face {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 100;
    src:
      local('Exo Thin '),
      local('Exo-Thin'),
      url('../../../assets/fonts/Exo/Exo-Thin.ttf') format('ttf')
  }
  
  /* exo-100italic - latin */
  @font-face {
    font-family: 'Exo';
    font-style: italic;
    font-weight: 100;
    src:
      local('Exo Thin italic'),
      local('Exo-Thinitalic'),
      url('../../../assets/fonts/Exo/Exo-ThinItalic.ttf') format('ttf')
  }
  
  /* exo-200normal - latin */
  @font-face {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 200;
    src:
      local('Exo Extra Light '),
      local('Exo-Extra Light'),
      url('../../../assets/fonts/Exo/Exo-ExtraLight.ttf') format('ttf')
  }
  
  /* exo-300normal - latin */
  @font-face {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 300;
    src:
      local('Exo Light '),
      local('Exo-Light'),
      url('../../../assets/fonts/Exo/Exo-Light.ttf') format('ttf')
  }
  
  /* exo-200italic - latin */
  @font-face {
    font-family: 'Exo';
    font-style: italic;
    font-weight: 200;
    src:
      local('Exo Extra Light italic'),
      local('Exo-Extra Lightitalic'),
      url('../../../assets/fonts/Exo/Exo-ExtraLightItalic.ttf') format('ttf')
  }
  
  /* exo-300italic - latin */
  @font-face {
    font-family: 'Exo';
    font-style: italic;
    font-weight: 300;
    src:
      local('Exo Light italic'),
      local('Exo-Lightitalic'),
      url('../../../assets/fonts/Exo/Exo-LightItalic.ttf') format('ttf')
  }
  
  /* exo-400normal - latin */
  @font-face {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 400;
    src:
      local('Exo Regular '),
      local('Exo-Regular'),
      url('../../../assets/fonts/Exo/Exo-Regular.ttf') format('ttf')
  }
  
  /* exo-400italic - latin */
  @font-face {
    font-family: 'Exo';
    font-style: italic;
    font-weight: 400;
    src:
      local('Exo Regular italic'),
      local('Exo-Regularitalic'),
      url('../../../assets/fonts/Exo/Exo-Italic.ttf') format('ttf')
  }
  
  /* exo-500normal - latin */
  @font-face {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 500;
    src:
      local('Exo Medium '),
      local('Exo-Medium'),
      url('../../../assets/fonts/Exo/Exo-Medium.ttf') format('ttf')
  }
  
  /* exo-500italic - latin */
  @font-face {
    font-family: 'Exo';
    font-style: italic;
    font-weight: 500;
    src:
      local('Exo Medium italic'),
      local('Exo-Mediumitalic'),
      url('../../../assets/fonts/Exo/Exo-MediumItalic.ttf') format('ttf')
  }
  
  /* exo-600italic - latin */
  @font-face {
    font-family: 'Exo';
    font-style: italic;
    font-weight: 600;
    src:
      local('Exo SemiBold italic'),
      local('Exo-SemiBolditalic'),
      url('../../../assets/fonts/Exo/Exo-SemiBoldItalic.ttf') format('ttf')
  }
  
  /* exo-600normal - latin */
  @font-face {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 600;
    src:
      local('Exo SemiBold '),
      local('Exo-SemiBold'),
      url('../../../assets/fonts/Exo/Exo-SemiBold.ttf') format('ttf')
  }
  
  /* exo-700normal - latin */
  @font-face {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 700;
    src:
      local('Exo Bold '),
      local('Exo-Bold'),
      url('../../../assets/fonts/Exo/Exo-Bold.ttf') format('ttf')
  }
  
  /* exo-800normal - latin */
  @font-face {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 800;
    src:
      local('Exo ExtraBold '),
      local('Exo-ExtraBold'),
      url('../../../assets/fonts/Exo/Exo-ExtraBold.ttf') format('ttf')
  }
  
  /* exo-700italic - latin */
  @font-face {
    font-family: 'Exo';
    font-style: italic;
    font-weight: 700;
    src:
      local('Exo Bold italic'),
      local('Exo-Bolditalic'),
      url('../../../assets/fonts/Exo/Exo-BoldItalic.ttf') format('ttf')
  }
  
  /* exo-800italic - latin */
  @font-face {
    font-family: 'Exo';
    font-style: italic;
    font-weight: 800;
    src:
      local('Exo ExtraBold italic'),
      local('Exo-ExtraBolditalic'),
      url('../../../assets/fonts/Exo/Exo-ExtraBoldItalic.ttf') format('ttf')
  }
  
  /* exo-900normal - latin */
  @font-face {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 900;
    src:
      local('Exo Black '),
      local('Exo-Black'),
      url('../../../assets/fonts/Exo/Exo-Black.ttf') format('ttf')
  }
  
  /* exo-900italic - latin */
  @font-face {
    font-family: 'Exo';
    font-style: italic;
    font-weight: 900;
    src:
      local('Exo Black italic'),
      local('Exo-Blackitalic'),
      url('../../../assets/fonts/Exo/Exo-BlackItalic.ttf') format('ttf')
  }
  
  