$gutters: ();
// themes - our custom or/and out of the box themes
@import 'themes';
// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
// @import '~@nebular/auth/styles/all';
@import '@nebular/bootstrap/styles/globals';
// ...
// global app font size
@import './font-size';
// global app exo font
@import './font-exo';
// loading progress bar theme
@import './pace.theme';
@import './bootstrap-rtl';

// install the framework and custom global styles
@include nb-install() {
    // framework global styles
    @include nb-theme-global();
    // @include nb-auth-global();
    @include nb-bootstrap-global();
    // loading progress bar
    @include ngx-pace-theme();

    // fixed in rc.9 and can be removed after upgrade
    .custom-control .custom-control-indicator {
        border-radius: 50%; // TODO: quickfix for https://github.com/akveo/nebular/issues/275
    }

    @include bootstrap-rtl();
}

.cdk-global-scrollblock {
    position: initial;
    width: initial;
    overflow: hidden;
}

.tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 0.7;
    white-space: nowrap;
}

.map {
    .tooltip-measure {
        opacity: 1;
        font-weight: bold;
    }

    .tooltip-static {
        background-color: #ffcc33;
        color: black;
        border: 1px solid white;
        opacity: 1;
    }

    .tooltip-measure:before,
    .tooltip-static:before {
        border-top: 6px solid rgba(0, 0, 0, 0.5);
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        content: '';
        position: absolute;
        bottom: -6px;
        margin-left: -7px;
        left: 50%;
    }

    .tooltip-static:before {
        border-top-color: #ffcc33;
    }
}

#map {
    .ol-overlaycontainer-stopevent {
        top: 0;

        .ol-scale-bar-inner {
            .ol-scale-text {
                text-shadow: none;
                font-weight: bold;
            }

            .ol-scale-step-text {
                text-shadow: none;
                font-weight: bold;
            }
        }
    }
    .ol-overlaycontainer {
        top: 0;
    }
}

.pointer {
    cursor: pointer;
}

.nb-theme-default .data-requests-table ng2-smart-table .ng2-smart-actions a.ng2-smart-action {
    position: absolute;
}

.nb-theme-default ng2-smart-table table tr td {
    font-size: 0.9rem;
}

.nb-theme-default ng2-smart-table table {
    table-layout: fixed;
}

.user-container {
    cursor: pointer;
}

.no-wrap {
    white-space: nowrap;
}

// submission list table row styles

.green-row {
    background-color: rgba(119, 255, 0, 0.5) !important;
    &:hover {
        background-color: rgba(119, 255, 0, 0.25) !important;
    }
}

.yellow-row {
    background-color: rgba(255, 234, 0, 0.5) !important;
    &:hover {
        background-color: rgba(255, 234, 0, 0.25) !important;
    }
}

.red-row {
    background-color: rgba(255, 0, 0, 0.5) !important;
    &:hover {
        background-color: rgba(255, 0, 0, 0.25) !important;
    }
}

.blue-row {
    background-color: rgba(0, 128, 255, 0.5) !important;
    &:hover {
        background-color: rgba(0, 128, 255, 0.25) !important;
    }
}

.gray-row {
    background-color: rgba(200, 200, 200, 0.5) !important;
    &:hover {
        background-color: rgba(200, 200, 200, 0.25) !important;
    }
}

.restriction-condition-parent {
    background-color: rgba(200, 200, 200, 0.5) !important;
    &:hover {
        background-color: rgba(200, 200, 200, 0.25) !important;
    }
    td {
        font-weight: bold !important;
    }
}

.pointer {
    cursor: pointer;
}

.ml-2 {
    margin-left: 0.5rem;
}

.ml-3 {
    margin-left: 1rem;
}

.ml-4 {
    margin-left: 2rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.mr-3 {
    margin-right: 1rem;
}

.mb-2 {
    margin-bottom: 0.5rem;
}

.mb-2-imp {
    margin-bottom: 0.5rem !important;
}

.mb-3 {
    margin-bottom: 1rem;
}

.mt-2 {
    margin-top: 0.5rem;
}

.mt-3 {
    margin-top: 1rem;
}

.gap-2 {
    gap: 0.5rem;
}

.gap-3 {
    gap: 1rem;
}

.gap-4 {
    gap: 2rem;
}

.break-word {
    word-wrap: break-word;
}

.text-danger {
    color: rgba(255, 0, 0, 1);
}

.text-success {
    color: rgba(119, 255, 0, 1);
}

.text-warning {
    color: rgba(255, 234, 0, 1);
}
